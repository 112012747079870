import React, { Component } from 'react'
import EmployeeCard from './EmployeeCard'

export default class TeamBlock extends Component {
  render() {
    return (
      <div
        className="gdlr-core-pbf-wrapper "
        style={{ padding: '90px 0px 20px 0px' }}
      >
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div
              className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first"
              data-skin="Grey"
            >
              <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr"
                      style={{ paddingBottom: 15 }}
                    >
                      <div className="gdlr-core-title-item-title-wrap ">
                        <h3
                          className="gdlr-core-title-item-title gdlr-core-skin-title "
                          style={{
                            fontSize: 37,
                            fontWeight: 600,
                            textTransform: 'none',
                          }}
                        >
                          Meet our team<span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-divider-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-divider-item-normal"
                      style={{ paddingBottom: 70 }}
                    >
                      <div
                        className="gdlr-core-divider-container gdlr-core-left-align"
                        style={{ maxWidth: 40 }}
                      >
                        <div
                          className="gdlr-core-divider-line gdlr-core-skin-divider"
                          style={{
                            borderColor: '#333333',
                            borderBottomWidth: 2,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gdlr-core-pbf-element">
              <div className="gdlr-core-personnel-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-personnel-item-style-grid gdlr-core-personnel-style-grid gdlr-core-with-divider ">
                <div className="gdlr-core-column-first">
                  <EmployeeCard
                    name="Mohamed Majapa"
                    title="Managing partner, Co-founder"
                    linkedin="https://www.linkedin.com/in/mohamed-majapa-2a93a2b2/"
                    twitter="https://twitter.com/Ti_Mj"
                    bio="Mohamed is an Entrepreneur and MBA Graduate (Cum laude - University of Cape Town) with a background in financial economics, market research and strategy formulation. His current area of focus is developing growth strategies for entrepreneurs, businesses and corporations, particularly those looking to venture in new markets within sub-Saharan Africa."
                    image={this.props.personnel1}
                  />
                </div>
                <EmployeeCard
                  name="Steven Sajja"
                  title="Technology, Co-founder"
                  linkedin="https://za.linkedin.com/in/stevensajja"
                  twitter="https://twitter.com/sajeezy"
                  bio="Steven has engaged in multiple facets of product development from user experience, architectural planning and software development. He comes with a great knowledge of Fintech, strategy planning and has consulted with multiple firms in the Sub-saharan Africa over an array of topics like tech, business strategy and process formulation & strategy."
                  image={this.props.personnel2}
                />
                <EmployeeCard
                  name="Mark Schreiber"
                  title="Senior consultant, business development"
                  linkedin="https://za.linkedin.com/in/markaschreiber"
                  twitter="https://twitter.com/MarkASchreiber"
                  bio="Mark is a qualified Geometrician and MBA Graduate. He has gained 10 years experience in the engineering industry, and over 12 years of management experience in a small professional environment. This has prepared him to deal with all aspects of business. Mark is also the founder of Evomatix and Mark Schreiber & Associates doing business in Africa and based in Cape Town."
                  image={this.props.personnel3}
                />
                <div className="gdlr-core-column-first">
                  <EmployeeCard
                    name="Sophie Mwinyi"
                    title="Trainee consultant"
                    linkedin="https://www.linkedin.com/in/sophie-mwinyi-3b1b629a/"
                    twitter="https://twitter.com/sajeezy"
                    bio="Sophie has a degree in Economics and Finance from UCT (Distinction) and is currently pursuing an Honors degree in corporate finance and Investments. Sophie's work involves consulting for a group of small to medium enterprises in Southern and Eastern Africa"
                    image={this.props.personnel4}
                    first
                  />
                </div>
                <EmployeeCard
                  name="Kevin Kageuka"
                  title="Market Research Associate"
                  linkedin="https://www.linkedin.com/in/kevin-kageuka-0a849994/?ppe=1"
                  twitter="https://twitter.com/kevinkageuka"
                  bio="Kevin has over 5 years experience in financial sector in sub-saharan Africa working across financial research, private and corporate finance and debt counselling. He has a degree in financial analysis and portfolio management from University of Cape Town. His current focus is in Market research and financial advisory within East African market."
                  image={this.props.personnel5}
                />
                <EmployeeCard
                  name="Louis Kinunda"
                  title="Senior consultant, Business Intelligence"
                  linkedin="https://www.linkedin.com/in/louis-kinunda-10aaa533/"
                  twitter="https://twitter.com/Louis_K22"
                  bio="Louis Kinunda is a Business Intelligence Specialist and MBA graduate who has extensive experience in IT and Telecoms industry. He has worked in multiple BI projects and IT systems implementation focusing in unlocking value and growth through technology capabilities. He is also passionate about Strategy development and Innovation space"
                  image={this.props.personnel6}
                />
                <div className="gdlr-core-column-first">
                  <EmployeeCard
                    name="Stanford Cheelo Mujuta"
                    title="Economist and Market Research"
                    linkedin="https://za.linkedin.com/in/stanford-cheelo-mujuta-b17a2255"
                    bio="Stanford has an advanced degree in economics - with working experience in market and economic research, strategy and consulting in the African region. His current activities involve brand-tracking, consumer experience and economic research."
                    image={this.props.personnel7}
                  />
                </div>
                <div className="gdlr-core-column">
                  <EmployeeCard
                    name="Sekelwa Mpambo"
                    title="Language & Communications"
                    linkedin="https://www.linkedin.com/in/sekelwa-mpambo-249ab74a/"
                    bio="Sekelwa Mpambo is a qualified Language Practitioner who regards herself as a language and communications champion. Her multifaceted background in the language and communications space encompasses creative writing, language training, translation, language research, editing, and corporate communications. Currently, Sekelwa’s experience and vision have led her to develop specialties in integrated marketing and communications where she hones in on engaging various content and texts on different platforms. She also doubles up as a make up artist and nail technician."
                    image={this.props.personnel8}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
