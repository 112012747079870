import React, { Component } from 'react'
import Blockquotes from '../Common/Blockquotes'

export default class Values extends Component {
  render() {
    const aboutIcon = require('../../assets/images/about-icon-1.png')
    const aboutIcon2 = require('../../assets/images/about-icon-2.png')
    const aboutIcon3 = require('../../assets/images/about-icon-3.png')

    return (
      <div
        className="gdlr-core-pbf-wrapper "
        style={{ padding: '85px 0px 35px 0px' }}
      >
        <Blockquotes title="We partner with entrepreneurs to transform their businesses from success to significance." />
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div className="gdlr-core-pbf-column gdlr-core-column-20 gdlr-core-column-first">
              <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-column-service-item gdlr-core-item-pdb  gdlr-core-center-align gdlr-core-no-caption gdlr-core-item-pdlr"
                      style={{ paddingBottom: 30 }}
                    >
                      <div
                        className="gdlr-core-column-service-media gdlr-core-media-image"
                        style={{ marginBottom: 30 }}
                      >
                        <img src={aboutIcon} width={70} height={60} />
                      </div>
                      <div className="gdlr-core-column-service-content-wrapper">
                        <div
                          className="gdlr-core-column-service-title-wrap"
                          style={{ marginBottom: 20 }}
                        >
                          <h3
                            className="gdlr-core-column-service-title"
                            style={{
                              fontSize: 19,
                              fontWeight: 500,
                              letterSpacing: 0,
                              textTransform: 'none',
                            }}
                          >
                            Commitment
                          </h3>
                        </div>
                        <div
                          className="gdlr-core-column-service-content"
                          style={{ fontSize: 16 }}
                        >
                          <p>
                            Our collective promise, "Growing with you" is a
                            reassuring pledge each team member makes to each of
                            our client and partners. We can only accomplish it
                            by embedding and living our Values on every project
                            we work on
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gdlr-core-pbf-column gdlr-core-column-20">
              <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-column-service-item gdlr-core-item-pdb  gdlr-core-center-align gdlr-core-no-caption gdlr-core-item-pdlr"
                      style={{ paddingBottom: 30 }}
                    >
                      <div
                        className="gdlr-core-column-service-media gdlr-core-media-image"
                        style={{ marginBottom: 26 }}
                      >
                        <img src={aboutIcon2} width={70} height={64} />
                      </div>
                      <div className="gdlr-core-column-service-content-wrapper">
                        <div
                          className="gdlr-core-column-service-title-wrap"
                          style={{ marginBottom: 20 }}
                        >
                          <h3
                            className="gdlr-core-column-service-title"
                            style={{
                              fontSize: 19,
                              fontWeight: 500,
                              letterSpacing: 0,
                              textTransform: 'none',
                            }}
                          >
                            Reliability
                          </h3>
                        </div>
                        <div
                          className="gdlr-core-column-service-content"
                          style={{ fontSize: 16 }}
                        >
                          <p>
                            Being profesional is a guarantee in our offering.
                            Our diversity and depth of experience, industry
                            knowledge and market presence ensures a peace of
                            mind to our customers. We keep our promises by
                            delivering and exceeding expectations.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gdlr-core-pbf-column gdlr-core-column-20">
              <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-column-service-item gdlr-core-item-pdb  gdlr-core-center-align gdlr-core-no-caption gdlr-core-item-pdlr"
                      style={{ paddingBottom: 30 }}
                    >
                      <div
                        className="gdlr-core-column-service-media gdlr-core-media-image"
                        style={{ marginBottom: 30 }}
                      >
                        <img src={aboutIcon3} width={61} height={61} />
                      </div>
                      <div className="gdlr-core-column-service-content-wrapper">
                        <div
                          className="gdlr-core-column-service-title-wrap"
                          style={{ marginBottom: 20 }}
                        >
                          <h3
                            className="gdlr-core-column-service-title"
                            style={{
                              fontSize: 19,
                              fontWeight: 500,
                              letterSpacing: 0,
                              textTransform: 'none',
                            }}
                          >
                            Agility
                          </h3>
                        </div>
                        <div
                          className="gdlr-core-column-service-content"
                          style={{ fontSize: 16 }}
                        >
                          <p>
                            Technology is at the cornerstone of our operations.
                            It is the reason for our agile approach to building
                            innovative and scalable solutions
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
