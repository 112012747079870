import React, { Component } from 'react'

export default class AboutBlock extends Component {
  render() {
    return (
      <div
        className="gdlr-core-pbf-wrapper "
        style={{ padding: '100px 0px 50px 0px' }}
        data-skin="Grey Text"
      >
        <div
          className="gdlr-core-pbf-background-wrap"
          style={{ backgroundColor: '#f6f6f6' }}
        />
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div className="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first">
              <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-title-item gdlr-core-item-pdb clearfix  gdlr-core-left-align gdlr-core-title-item-caption-top gdlr-core-item-pdlr">
                      <div className="gdlr-core-title-item-title-wrap ">
                        <h3
                          className="gdlr-core-title-item-title gdlr-core-skin-title "
                          style={{
                            fontSize: 33,
                            fontWeight: 400,
                            letterSpacing: 0,
                            textTransform: 'none',
                          }}
                        >
                          Read The Story Behind <br />Our Success<span className="gdlr-core-title-item-title-divider gdlr-core-skin-divider" />
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gdlr-core-pbf-column gdlr-core-column-30">
              <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js ">
                  <div className="gdlr-core-pbf-element">
                    <div className="gdlr-core-text-box-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-left-align">
                      <div
                        className="gdlr-core-text-box-item-content"
                        style={{ fontSize: 18 }}
                      >
                        <p>
                          <span style={{ color: '#3c4295' }}>
                            Bora Growth Partners was started by two friends
                            Mohamed Majapa and Sajja Steven.
                          </span>{' '}
                          Our passion for entrepreneurship and technology
                          brought us together - not to mention our common belief
                          that Africa is one big opportunity.
                        </p>
                        <p>
                          We approach the African business climate with an added
                          dimension of familiarity due to our respective
                          backgrounds. Our diversity in expertise, experience,
                          and hard work has created a receptive atmosphere where
                          curiosity and innovation guide our performance. It is
                          our hope to offer the same value to our business
                          partners, clients, and associates.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
