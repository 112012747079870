import React, { Component } from 'react'
import Layout from '../components/layout/layout'
import Hero from '../components/Hero'
import AboutBlock from '../components/About/AboutBlock'
import Values from '../components/About/Values'
import TeamBlock from '../components/About/TeamBlock'
import How from '../components/About/How'
import ContactCTA from '../components/CTA/ContactCTA'

export default class AboutPage extends Component {
  render() {
    const landingImage = require('../assets/images/nastuh-abootalebi-284884-unsplash.jpg')

    const personnel1 = require('../assets/images/majapa.png')
    const personnel2 = require('../assets/images/sajja.jpg')
    const personnel3 = require('../assets/images/mark.jpg')
    const personnel4 = require('../assets/images/sophie.jpg')
    const personnel5 = require('../assets/images/kevin.jpg')
    const personnel6 = require('../assets/images/loui.jpg')
    const personnel7 = require('../assets/images/stanford.jpeg')
    const personnel8 = require('../assets/images/sekelwa.jpg')

    return (
      <Layout location={this.props.location}>
        <div className="gdlr-core-page-builder-body">
          <Hero
            title="Hello. We're Bora Growth Partners"
            description="We are an innovative consulting firm creating great experiences for great entrepreneurs and businesses"
            heroImage={landingImage}
            withOpacity
          />
          <Values />
          <AboutBlock />
          <TeamBlock
            personnel1={personnel1}
            personnel2={personnel2}
            personnel3={personnel3}
            personnel4={personnel4}
            personnel5={personnel5}
            personnel6={personnel6}
            personnel7={personnel7}
            personnel8={personnel8}
          />
          <ContactCTA
            title="Looking for a job"
            description="We are always looking for amazing talent to join us. If you think you have what it takes, drop us a line."
          />
          <How />
        </div>
      </Layout>
    )
  }
}
