import React, { Component } from 'react'

export default class Blockquotes extends Component {
  renderLargeBlockquote() {
    return (
      <div className="gdlr-core-pbf-column gdlr-core-column-60 gdlr-core-column-first">
        <div className="gdlr-core-pbf-column-content-margin gdlr-core-js ">
          <div
            className="gdlr-core-pbf-column-content clearfix gdlr-core-js "
            style={{ maxWidth: 900 }}
          >
            <div className="gdlr-core-pbf-element">
              <div
                className="gdlr-core-blockquote-item gdlr-core-item-pdlr gdlr-core-item-pdb  gdlr-core-center-align gdlr-core-large-size"
                style={{ paddingBottom: 40 }}
              >
                <div className="gdlr-core-blockquote gdlr-core-info-font">
                  <div className="gdlr-core-blockquote-item-quote gdlr-core-quote-font gdlr-core-skin-icon">
                    “
                  </div>
                  <div className="gdlr-core-blockquote-item-content-wrap">
                    <div className="gdlr-core-blockquote-item-content gdlr-core-skin-content">
                      <p>{this.props.title}</p>
                    </div>
                    {/* <div className="gdlr-core-blockquote-item-author gdlr-core-skin-caption">
                      John Smith
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  render() {
    return <div>{this.renderLargeBlockquote()}</div>
  }
}
