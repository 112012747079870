import React, { Component } from 'react'

export default class How extends Component {
  render() {
    const shutterstock1 = require('../../assets/images/mia-baker-359838-unsplash.jpg')

    const aboutIcon = require('../../assets/images/about-icon-7.png')
    const aboutIcon2 = require('../../assets/images/about-icon-8.png')
    const aboutIcon3 = require('../../assets/images/about-icon-9.png')

    return (
      <div
        className="gdlr-core-pbf-wrapper "
        style={{ padding: '55px 0px 0px 0px' }}
        data-skin="Purple Personnel"
      >
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div className="gdlr-core-pbf-column gdlr-core-column-30 gdlr-core-column-first">
              <div
                className="gdlr-core-pbf-column-content-margin gdlr-core-js  gdlr-core-column-extend-left"
                data-sync-height="half-height"
                style={{ minHeight: '500px' }}
              >
                <div className="gdlr-core-pbf-background-wrap">
                  <div
                    className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
                    style={{
                      backgroundImage: `url(${shutterstock1})`,
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                    }}
                    data-parallax-speed="0.2"
                  />
                </div>
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content" />
              </div>
            </div>
            <div className="gdlr-core-pbf-column gdlr-core-column-30">
              <div
                className="gdlr-core-pbf-column-content-margin gdlr-core-js "
                style={{ padding: '85px 0px 25px 60px' }}
                data-sync-height="half-height"
              >
                <div className="gdlr-core-pbf-column-content clearfix gdlr-core-js  gdlr-core-sync-height-content">
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-column-service-item gdlr-core-item-pdb  gdlr-core-left-align gdlr-core-column-service-icon-left gdlr-core-no-caption gdlr-core-item-pdlr"
                      style={{ paddingBottom: 30 }}
                    >
                      <div
                        className="gdlr-core-column-service-media gdlr-core-media-image"
                        style={{ marginTop: 10, marginRight: 30 }}
                      >
                        <img src={aboutIcon} alt width={46} height={46} />
                      </div>
                      <div className="gdlr-core-column-service-content-wrapper">
                        <div className="gdlr-core-column-service-title-wrap">
                          <h3
                            className="gdlr-core-column-service-title"
                            style={{
                              fontSize: 22,
                              fontWeight: 600,
                              textTransform: 'none',
                            }}
                          >
                            On Time Service.
                          </h3>
                        </div>
                        <div
                          className="gdlr-core-column-service-content"
                          style={{ fontSize: 16 }}
                        >
                          <p>
                            Transforming distribution and marketing with key
                            capabilities in customer insight and analytics,
                            omni-chan
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-column-service-item gdlr-core-item-pdb  gdlr-core-left-align gdlr-core-column-service-icon-left gdlr-core-no-caption gdlr-core-item-pdlr"
                      style={{ paddingBottom: 30 }}
                    >
                      <div
                        className="gdlr-core-column-service-media gdlr-core-media-image"
                        style={{ marginTop: 10, marginRight: 30 }}
                      >
                        <img src={aboutIcon2} alt width={45} height={45} />
                      </div>
                      <div className="gdlr-core-column-service-content-wrapper">
                        <div className="gdlr-core-column-service-title-wrap">
                          <h3
                            className="gdlr-core-column-service-title"
                            style={{
                              fontSize: 22,
                              fontWeight: 600,
                              textTransform: 'none',
                            }}
                          >
                            A Team Of Professionals.
                          </h3>
                        </div>
                        <div
                          className="gdlr-core-column-service-content"
                          style={{ fontSize: 16 }}
                        >
                          <p>
                            Transforming distribution and marketing with key
                            capabilities in customer insight and analytics,
                            omni-chan
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="gdlr-core-pbf-element">
                    <div
                      className="gdlr-core-column-service-item gdlr-core-item-pdb  gdlr-core-left-align gdlr-core-column-service-icon-left gdlr-core-no-caption gdlr-core-item-pdlr"
                      style={{ paddingBottom: 30 }}
                    >
                      <div
                        className="gdlr-core-column-service-media gdlr-core-media-image"
                        style={{ marginTop: 10, marginRight: 30 }}
                      >
                        <img src={aboutIcon3} alt width={46} height={46} />
                      </div>
                      <div className="gdlr-core-column-service-content-wrapper">
                        <div className="gdlr-core-column-service-title-wrap">
                          <h3
                            className="gdlr-core-column-service-title"
                            style={{
                              fontSize: 22,
                              fontWeight: 600,
                              textTransform: 'none',
                            }}
                          >
                            Analyze Your Business.
                          </h3>
                        </div>
                        <div
                          className="gdlr-core-column-service-content"
                          style={{ fontSize: 16 }}
                        >
                          <p>
                            Transforming distribution and marketing with key
                            capabilities in customer insight and analytics,
                            omni-chan
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
