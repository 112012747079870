import React, { Component } from 'react'
import { Link } from 'gatsby'
export default class ContactCTA extends Component {
  render() {
    const backgroundImage = require('../../assets/images/Bora-Pattern-05.png')

    return (
      <div
        className="gdlr-core-pbf-wrapper "
        style={{ padding: '75px 0px 45px 0px', backgroundColor: '#143b51' }}
        data-skin="Home CTA"
      >
        <div className="gdlr-core-pbf-background-wrap">
          <div
            className="gdlr-core-pbf-background gdlr-core-parallax gdlr-core-js"
            style={{
              opacity: '0.2',
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            data-parallax-speed="0.1"
          />
        </div>
        <div className="gdlr-core-pbf-wrapper-content gdlr-core-js ">
          <div className="gdlr-core-pbf-wrapper-container clearfix gdlr-core-container">
            <div className="gdlr-core-pbf-element">
              <div className="gdlr-core-call-to-action-item gdlr-core-item-pdlr gdlr-core-item-pdb gdlr-core-style-left-align-right-button">
                <div className="gdlr-core-call-to-action-item-inner">
                  <div className="gdlr-core-call-to-action-item-content-wrap">
                    <h3
                      className="gdlr-core-call-to-action-item-title"
                      style={{
                        fontSize: 31,
                        fontWeight: 400,
                        letterSpacing: 0,
                      }}
                    >
                      {this.props.title}
                    </h3>
                    <div
                      className="gdlr-core-call-to-action-item-caption gdlr-core-title-font gdlr-core-skin-caption"
                      style={{ color: 'white' }}
                    >
                      {this.props.description}
                    </div>
                  </div>
                  <div className="gdlr-core-call-to-action-item-button">
                    <Link
                      className="gdlr-core-button  gdlr-core-button-solid gdlr-core-button-no-border"
                      to="/contact"
                      style={{
                        borderRadius: 30,
                        MozBorderRadius: 30,
                        WebkitBorderRadius: 30,
                      }}
                    >
                      <span className="gdlr-core-content">Contact Us</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
